.footer-container{
    display: flex;
    justify-content: center;    
    margin-top: 8rem;
    margin-bottom: 4rem;
    position: relative; 
    
    .footer{
        border-top: 0.5px solid hsl(0, 0%, 83%);
        display: flex;
        flex-direction: column;
        gap: 1.7em;
        width: 95vw;
        justify-content: center;

        .footer-top{
            // display: flex;
            // justify-content: space-between;
            display: grid;
            grid-template-columns: repeat(3,1fr);
            align-items: center;

            .logo-container{
                display: flex;
                text-decoration: none;
                color: #523d35;
                // margin-left: 1rem;
                align-items: center;
                
                img{
                    width: 50px;
                    height: 50px;
                    padding-left: .8rem;
                }
        
                .logo{
                    letter-spacing: 1px;
                    display: flex;
                    gap: 2px;
                    font-weight: 1000;
                    font-family: "Recursive", sans-serif;
                    p{
                    }
                }
            }

            .footer-links{
                display: flex;
                justify-content: center;
                list-style: none;
                gap: 2rem;

                a{
                    text-decoration: none;
                    color: black;
                }
            }

            .socials{
                margin-right: 2rem;
                display: flex;
                gap: 2rem;
                font-size: 25px;
                justify-content: flex-end;
                color: white;

                a{
                    text-decoration: none;
                    color: #333;
                }

                .footer-icon{
                    cursor: pointer;
                    transition: 0.3s ease-in-out;
                    color-scheme: white;

                    &:hover{
                        transform: scale(1.1);
                    }
                }
            }
            
        }

        .footer-bottom{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 2rem;
             font-size: 14px;
             color: #333;

             a{
                cursor: pointer;
                text-decoration: none;
                color: #333;
             }
             
        }
    }
}

.newsletter-footer {
 margin-top: 2rem;
 display: none;
    .footer{
        border: none;
    }
  }

 .tickets-footer{
    margin-top: 0;

    .footer{
        border-top: 0.5px solid hsl(0, 0%, 83%);
    }
 } 

@media screen and (max-width: 1040px) {
.footer-container{

    .footer{
        width: 90vw;
        overflow-x:hidden;


        .footer-top{
            display: flex;
            flex-direction: column;
            gap: 1rem;
            justify-content: center;

            .logo-container{
                font-size: smaller;
                margin-left: 0;
            }

            .footer-links{
                font-size: smaller;
                gap: 1.5rem;
                margin-bottom: 1rem;
            }

            .socials{
                margin-right: 0;
                gap: 0.7em;
                font-size: 20px;
            }
        }
        
        .footer-bottom{
        font-size: smaller;
        flex-direction: column-reverse;
        gap: 1rem;
        }
    }
}
}