.masterclass-app{
    width: 100%;
    
    .popup{
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0 , 0, 0.5);
        z-index: 10000;

        .spinner {
            position: absolute;
            width: 9px;
            height: 9px;
          }
          
          .spinner div {
            position: absolute;
            width: 50%;
            height: 150%;
            background: white;
            transform: rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1%));
            animation: spinner-fzua35 1s calc(var(--delay) * 1s) infinite ease;
          }
          
          .spinner div:nth-child(1) {
            --delay: 0.1;
            --rotation: 36;
            --translation: 150;
          }
          
          .spinner div:nth-child(2) {
            --delay: 0.2;
            --rotation: 72;
            --translation: 150;
          }
          
          .spinner div:nth-child(3) {
            --delay: 0.3;
            --rotation: 108;
            --translation: 150;
          }
          
          .spinner div:nth-child(4) {
            --delay: 0.4;
            --rotation: 144;
            --translation: 150;
          }
          
          .spinner div:nth-child(5) {
            --delay: 0.5;
            --rotation: 180;
            --translation: 150;
          }
          
          .spinner div:nth-child(6) {
            --delay: 0.6;
            --rotation: 216;
            --translation: 150;
          }
          
          .spinner div:nth-child(7) {
            --delay: 0.7;
            --rotation: 252;
            --translation: 150;
          }
          
          .spinner div:nth-child(8) {
            --delay: 0.8;
            --rotation: 288;
            --translation: 150;
          }
          
          .spinner div:nth-child(9) {
            --delay: 0.9;
            --rotation: 324;
            --translation: 150;
          }
          
          .spinner div:nth-child(10) {
            --delay: 1;
            --rotation: 360;
            --translation: 150;
          }
          
          @keyframes spinner-fzua35 {
            0%, 10%, 20%, 30%, 50%, 60%, 70%, 80%, 90%, 100% {
              transform: rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1%));
            }
          
            50% {
              transform: rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1.5%));
            }
          }
    }

    .checkout-popup{
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 10000;
        background: rgba(0, 0 , 0, 0.5);


        .checkout-container{
                display: inherit;
                flex-direction: inherit;
                justify-content: inherit;
                align-items: inherit;
                gap: 1.5rem;
                text-align: center;
                background: white;
                padding: 5rem 3rem 3rem 3rem;
                border-radius: 1rem;
                

                p{
                    font-weight: 500;
                    color: #333;
                    line-height: 1.5;

                    span{
                        font-weight: 600;
                    }
                }

                .buttons{
                    // margin-top: 2rem;
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                    margin-top: 2.5rem;

                    .a{
                        width: 100%;
                        padding: 15px 8px;
                        border-radius: 25px;
                        border: none;
                        cursor: pointer;
                        width: 250px;
                        font-weight: 600;
                        text-decoration: none;
                        transition: .3s ease;
                        background: #228B22;
                        color: white;
                        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

                        &:hover{
                            transform: scale(.9)
                        }

                     
                    }

                    .again{
                        background: #FF4C4C;
                    }
                }

              .error{
                background: #FF4C4C;
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100px;
                height: 100px;
                border-radius: 50%;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

                h1{
                    font-size: 3.5rem;
                }

                .ticket-icon{
                    font-size: 3rem;
                }
              } 

              .success{
                background: #228B22;
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100px;
                height: 100px;
                border-radius: 50%;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

                h1{
                    font-size: 3.5rem;
                }

                .ticket-icon{
                    font-size: 3rem;
                }
              } 

            .checkbox-wrapper {
                position: relative;
                display: inline-block;
                width: 90px;
                height: 90px;
              }
              
              .checkbox-wrapper .background {
                fill:#228B22;
                transition: ease all 0.6s;
                -webkit-transition: ease all 0.6s;
              }
              
              .checkbox-wrapper .stroke {
                fill: none;
                stroke: #fff;
                stroke-miterlimit: 10;
                stroke-width: 2px;
                stroke-dashoffset: 100;
                stroke-dasharray: 100;
                transition: ease all 0.6s;
                -webkit-transition: ease all 0.6s;
              }
              
              .checkbox-wrapper .check {
                fill: none;
                stroke: #fff;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-width: 3px;
                stroke-dashoffset: 22;
                stroke-dasharray: 22;
                animation: dash 2s ease infinite;
              }
              
              @keyframes dash {
                0% {
                  stroke-dashoffset: 22;
                }
                50% {
                  stroke-dashoffset: 0;
                }
                100% {
                  stroke-dashoffset: 22;
                }
              }
              
              .checkbox-wrapper input[type=checkbox] {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                margin: 0;
                opacity: 0;
                appearance: none;
                -webkit-appearance: none;
              }
              
              .checkbox-wrapper input[type=checkbox]:hover {
                cursor: pointer;
              }
              
              .checkbox-wrapper input[type=checkbox]:checked + svg .background {
                fill: #228B22;
              }
              
              .checkbox-wrapper input[type=checkbox]:checked + svg .stroke {
                stroke-dashoffset: 0;
              }
              
              .checkbox-wrapper input[type=checkbox]:checked + svg .check {
                stroke-dashoffset: 0;
                animation: none;
              }
              
        }
    }

    .body{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        
        background: rgba(0, 0, 0, 0.5) url('../../stock/backgrounds/WhatsApp\ Image\ 2025-03-26\ at\ 11.42.21_b2c497b4.jpg')no-repeat;
        background-size: cover;
        // background-position: 100% 20%;
        background-position: 80% 70%;
        background-blend-mode: overlay;
        position: fixed;
        height: 100vh;
        overflow-y: hidden;
        
    

        .content{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
      
            text-align: center;
            line-height: 1.5;
            color: white;
        position: relative;

        height: fit-content;
        margin-top: 5rem;


            h1{
                font-size: 5em;
                // color: #333;
                margin-bottom: 10px;
                display: flex;
                align-self: center;
                justify-content: center;
                line-height: 1.15;
            }

            .subtext{
                font-size: 1em;
                color: #eee;
                margin-bottom: 1rem;
              }

              .small-text{
                font-size: .5em;
                margin-top: 1rem;
              }

              .sub-container{
                position: relative;
                border: 1px solid #eee;
                padding: .7rem 1rem;
                border-radius: 5.5rem;
                display: flex;
                margin: 0 auto;
                // justify-content: space-between;

                gap: 4rem;
                width: fit-content;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
            
                input{
                    // padding: 1rem 0;
                    outline: none;
                    border: none;
                    background: none;
                    color: #eee;

                    &::placeholder{
                        color: #eee;
                    }
                }

                button{
                    padding: 8px 15px;
                    border: none;
                    background: #FF7E00;
                    border-radius: 20px;
                    color: white;
                    cursor: pointer;
                    font-weight: 600;
                    transition: .3s ease-in-out;

                    &:hover{
                        transform: scale(.95);
                    }
                }
            }

              
              .form-socials{
                margin-top: 1.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 1.5rem;
                font-size: 22.5px;
                
                a{
                    text-decoration: none;
                    color: white;
                }

              .icon{
                cursor: pointer;
                transition: .3s ease-in-out;


                &:hover{
                    transform: scale(0.9);
                }
              }
            }
              
        }
    }
}

@media screen and (max-width: 720px) {
.masterclass-app{

    .body{
        margin-bottom: 0;

        .content{
            font-size: 90%;
        
            gap: 1rem;
            margin: 0;

            .sub-container{
                gap: 0;
                justify-content: space-between;
            }

            h1{
              font-size: 3em;
            }

            .subtext{
              width: 90%;
            }
        }
    }

    .checkout-popup{

        .checkout-container{
            width: 60vw;
            font-size: 90%;
            padding: 3rem 3rem 3rem 3rem;
    
        }
    }
}
}