@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');
@import './component_styles/Navbar.scss';
@import './component_styles/Footer.scss';
@import './pages_style/Home.scss';
@import './pages_style/About.scss';
@import './pages_style/Contact.scss';
@import './pages_style/Masterclass.scss';
@import './pages_style/Shop.scss';
@import url('./pages_style/NotFound.scss');
@import url('./pages_style/CountdownTimer.scss');
@import url('./pages_style/Presale.scss');
@import url('./pages_style/Tickets.scss');
@import url('./component_styles/NavCountdown.scss');

*{
    font-family: 'Montserrat', sans-serif;
    // user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}



.pagewidth{
    display: flex;
    justify-content: center;
    
}

.pagecontent{
    width: 90vw;
}